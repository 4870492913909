import React, { useState } from "react";
import "../styling/Contact.css";
import Footer from "./Footer";
import emailjs from "emailjs-com";
import EmailModal from "./EmailModal";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    const serviceId = process.env.REACT_APP_SERVICE_ID;
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_API_KEY;

    emailjs.sendForm(serviceId, templateId, e.target, publicKey).then(
      (result) => {
        console.log("Email sent", result.text);
        setIsModalOpen(true);
        setName("");
        setEmail("");
        setMessage("");
      },
      (error) => {
        console.error("Error sending email", error.text);
      }
    );
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="model"></div>
      <form className="Contact-Form" onSubmit={sendEmail}>
        <label className="label">Name</label>
        <input
          required={true}
          className="input"
          name="from_name"
          placeholder="Name"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            e.target.setCustomValidity("");
          }}
        />

        <br />
        <label className="label">Email</label>
        <input
          type="email"
          className="input"
          name="email_id"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required={true}
          pattern="/^[\w\.-]+@[a-zA-Z\d\.-]+\.(com|org|net|edu|gov|mil|co\.za|[a-zA-Z]{2,})$/"
        />
        <br />

        <label className="label-message">Message</label>
        <textarea
          required={true}
          className="input-message"
          name="message"
          placeholder="Message"
          value={message}
          rows={10}
          onChange={(e) => setMessage(e.target.value)}
        />

        <div className="submitButton">
          <button type="submit" className="submit">
            Submit
          </button>
        </div>
      </form>

      <Footer />

      <EmailModal isOpen={isModalOpen} closeModal={closeModal} />
    </>
  );
};

export default Contact;
