import React from "react";
import "../styling/SocailResearch.css";
import { Parallax } from "react-parallax";
import banner from "../images/background.jpg";
import justice from "../images/justice.png";
import marraige from "../images/marriage.png";
import trauma from "../images/trauma.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Counseling = () => {
  return (
    <>
      <Parallax
        className="Main-banner"
        bgImage={banner}
        bgImageAlt="the cat"
        strength={400}
        bgImageSize
        blur={{ min: 0, max: 5 }}
      >
        <div className="Banner-text">
          <h1 className="text">Counselling</h1>
        </div>
      </Parallax>

      <div className="sectionTwo">
        <div className="section-block">
          <img className="image" src={trauma} />
          <h3 className="section-heading">Trauma Debriefing</h3>
          <p className="body-text">
            Experience healing and resilience through our Trauma Debriefing
            services. Our compassionate team is here to guide you through the
            process of understanding and overcoming trauma. In a safe and
            empathetic space, we work collaboratively to address the impact of
            past experiences, offering support and strategies for coping and
            recovery. Take the first step towards healing and rebuilding a
            stronger foundation for your well-being.
          </p>
        </div>
        <div className="section-block">
          <img className="image" src={marraige} />
          <h3 className="section-heading">Marraige Counselling</h3>
          <p className="body-text">
            Embark on a journey to strengthen your relationship through our
            Marriage Counselling services. Discover a safe and confidential
            space where open communication and understanding thrive. Our
            experienced counselling team is dedicated to helping couples
            navigate challenges, fostering deeper connections, and guiding you
            towards a resilient and harmonious partnership.
          </p>
        </div>
        <div className="section-block">
          <img className="image" src={justice} />
          <h3 className="section-heading">Family Mediation</h3>
          <p className="body-text">
            Navigate the intricate dynamics of family life with our Family
            Mediation services. We offer a supportive environment for open
            dialogue and resolution, where conflicts can be addressed
            constructively. Our mediation process aims to build bridges,
            fostering understanding and cooperation within families. Explore a
            path to harmonious relationships and lasting connections through our
            compassionate approach to family mediation.
          </p>
        </div>
      </div>
      <div className="Info">
        <div className="Research-Image-Counseling"></div>
        <div className="Info-caption">
          <h1 className="info-heading">
            Empathetic Counselling for Personal Growth and Well-Being
          </h1>
          <p className="info-copy">
            Life's journey can present challenges that sometimes feel
            overwhelming. At KFM Counselling, we are here to offer compassionate
            and professional counselling services tailored to your unique needs.
            Our team of experienced counselors is dedicated to providing a safe
            and supportive space where you can explore your thoughts, feelings,
            and concerns.
          </p>
          <button className="CTA">
            <Link className="to-Contact" to="/contact">
              Get in touch
            </Link>
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Counseling;
