import React from "react";
import "../styling/Footer.css";
import logo from "../images/logoo.png";

const Footer = () => {
  return (
    <>
      <div className="Footer">
        <div className="Main-Footer">
          <div className="Footer-Left">
            <h4 className="footer-name">Kgomotso Msimango</h4>
            <p className="footer-copy">Kgomotso@kfmconsulting.co.za</p>
            <p className="footer-copy">083 682 1039</p>
          </div>
          <div className="Footer-Right">
            <img className="Footer-Img" src={logo} />
          </div>
        </div>
        <p className="rights">© Copyright 2024. Made by RetroPixelz</p>
      </div>
    </>
  );
};

export default Footer;
