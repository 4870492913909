import React, { useState } from "react";
import "../styling/Nav.css";
import logo from "../images/logoo.png";
import { Outlet, Link, NavLink } from "react-router-dom";

const Nav = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };
  return (
    <>
      <div className="nav">
        <div className="nav-logo">
          <img className="logo" src={logo} alt="Logo" />
        </div>

        <div className={`nav-links ${isMobileMenuOpen ? "open" : ""}`}>
          <NavLink
            exact="true"
            to="/"
            className="nav-link"
            activeclassname="active-link"
          >
            <p>Home</p>
          </NavLink>

          <NavLink
            to="/SocailResearch"
            className="nav-link"
            activeclassname="active-link"
          >
            <p>Social Research</p>
          </NavLink>

          <NavLink
            to="/Counseling"
            className="nav-link"
            activeclassname="active-link"
          >
            <p>Counselling</p>
          </NavLink>

          <NavLink
            to="/contact"
            className="nav-link"
            activeclassname="active-link"
          >
            <p>Contact</p>
          </NavLink>
        </div>

        <div className="nav-mobile-toggle" onClick={toggleMobileMenu}>
          <div className={`burger ${isMobileMenuOpen ? "open" : ""}`}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
};

export default Nav;
