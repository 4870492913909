import React from "react";
import "../styling/SocailResearch.css";
import { Parallax } from "react-parallax";
import banner from "../images/background.jpg";
import social from "../images/social.png";
import manage from "../images/management.png";
import work from "../images/portfolio.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const SocailResearch = () => {
  return (
    <>
      <Parallax
        className="Main-banner"
        bgImage={banner}
        bgImageAlt="the cat"
        strength={400}
        bgImageSize
        blur={{ min: 0, max: 5 }}
      >
        <div className="Banner-text">
          <h1 className="text">
            Early Childhood
            <br />
            Development Projects
          </h1>
        </div>
      </Parallax>

      <div className="Info">
        <div className="Research-Image"></div>
        <div className="Info-caption">
          <h1 className="info-heading">
            Discovering Boundless Curiosity: Early Learning Adventures
          </h1>
          <p className="info-copy">
            In the heart of our social research journey in early child
            development, this captivating moment captures the joyous curiosity
            of adolescents exploring the world of learning. Through engaging and
            interactive sessions, we nurture the seeds of knowledge, fostering a
            love for discovery in our little learners. Every smile, every shared
            discovery is a testament to the transformative power of early
            education. 🌱✨
          </p>
          <button className="CTA">
            <Link className="to-Contact" to="/contact">
              Get in touch
            </Link>
          </button>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default SocailResearch;
