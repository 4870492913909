import "./App.css";
import Banner from "./components/Banner";
import Nav from "./components/Nav";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import Contact from "./components/Contact";
import SocailResearch from "./components/SocailResearch";
import Counseling from "./components/Counseling";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Nav />}>
          <Route index element={<Banner />} />
          <Route path="SocailResearch" element={<SocailResearch />} />\
          <Route path="contact" element={<Contact />} />
          <Route path="Counseling" element={<Counseling />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
