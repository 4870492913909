import React, { useEffect } from "react";
import Modal from "react-modal";
import "../styling/Contact.css";

const EmailModal = ({ isOpen, closeModal }) => {
  const appElement = document.getElementById("root");

  useEffect(() => {
    Modal.setAppElement(appElement);

    return () => {};
  }, [appElement]);
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Email Sent Modal"
      className="modal"
    >
      <h2 className="sent-text">Email Sent</h2>
      <p>Your email has been successfully sent.</p>
      <button onClick={closeModal} className="close">
        Close
      </button>
    </Modal>
  );
};

export default EmailModal;
