import React from "react";
import "../styling/Banner.css";
import { Parallax } from "react-parallax";
import banner from "../images/background.jpg";
import social from "../images/social.png";
import manage from "../images/management.png";
import work from "../images/portfolio.png";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const Banner = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Discover dedicated social work services at Your Social Worker Practice in South Africa. Our compassionate team offers tailored support for individuals and families, promoting emotional well-being and positive life changes. Contact us for professional and confidential assistance."
        />
      </Helmet>
      <Parallax
        className="Main-banner"
        bgImage={banner}
        bgImageAlt="the cat"
        strength={400}
        bgImageSize
        blur={{ min: 0, max: 5 }}
      >
        <div className="Banner-text">
          <h1 className="text">
            your partner in social research and mental health wellness
          </h1>
        </div>
      </Parallax>

      <div className="section">
        <div className="section-block">
          <img className="image" src={social} />
          <h3 className="section-heading">Social Research</h3>
          <p className="body-text">
            Explore the intricacies of societal dynamics through meticulous
            social research. Uncover valuable insights that pave the way for
            informed decisions and meaningful change.
          </p>
        </div>
        <div className="section-block">
          <img className="image" src={manage} />
          <h3 className="section-heading">Project Management</h3>
          <p className="body-text">
            Navigate the complexities of community initiatives with expert
            project management. From conceptualization to implementation, ensure
            that every project is executed seamlessly for maximum impact.
          </p>
        </div>
        <div className="section-block">
          <img className="image" src={work} />
          <h3 className="section-heading">Counselling</h3>
          <p className="body-text">
            Embark on a transformative journey through a diverse array of
            impactful counselling projects. Witness tangible outcomes and the
            positive influence on individuals and communities, showcasing the
            dedicated efforts and compassionate intervention at KFM counselling
            practice.
          </p>
        </div>
      </div>
      <div className="About-Section">
        <div className="About-Text-Section">
          <div className="line"></div>
          <h1>About us</h1>
          <p className="About-Body">
            At <strong>KFM Consulting</strong>, we are driven by a profound
            commitment to fostering positive change in the lives of individuals
            and the communities we serve. Founded on principles of compassion,
            empathy, and social responsibility, our mission is to address
            societal challenges through innovative solutions and unwavering
            dedication.
          </p>
          <h2>Who We Are:</h2>
          <p className="About-Body">
            As a leading force in social work and community development, we
            bring together a team of passionate professionals dedicated to
            making a lasting impact. With a focus on inclusivity and equality,
            we strive to create a world where everyone has the opportunity to
            thrive.
          </p>
          <h2>Our Approach:</h2>
          <p className="About-Body">
            At the heart of our approach is a deep understanding that real
            change begins with empathy and understanding. Through rigorous
            social research, strategic project management, and hands-on
            experience, we tailor our initiatives to address the unique needs of
            each community we engage with.
          </p>
          <h2>Our Values:</h2>
          <ul>
            <li>
              <strong>Compassion:</strong>
              <br />
              We approach every individual and community with empathy,
              recognizing the inherent dignity in every person.
            </li>
            <br />

            <li>
              <strong>Innovation:</strong>
              <br />
              We embrace creative and sustainable solutions to address social
              challenges and drive positive transformation.
            </li>
            <br />

            <li>
              <strong>Collaboration:</strong>
              <br />
              We believe in the power of partnerships, working closely with
              communities, stakeholders, and like-minded organizations to
              amplify our impact.
            </li>
          </ul>

          <h2>Making a Difference Together:</h2>
          <p className="About-Body">
            Join us in our journey to create a world where every person,
            regardless of their background, has the opportunity to lead a
            fulfilling and dignified life. Together, let's build stronger, more
            resilient communities.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Banner;
